<template>
  <div>
    <b-container class="">
      <div class="mt-4 mb-4">
        <h2 class="font-weight-bolder text-colorBlue text-center">
          Explore Treatments Across Specialties
        </h2>
        <b-row class="mt-1">
          <b-col
            v-for="department in departments"
            :key="department.id"
            lg="2"
            md="2"
            sm="2"
            class="d-flex align-items-center justify-content-md-start justify-content-center rounded py-50 mt-3"
          >
            <router-link
              :to="{
                name: 'Treatment',
                params: {
                  treatmentName: department.name,
                  id: department.id,
                },
              }"
            >
              <div class="d-flex align-items-center">
                <img
                  :src="department.image"
                  class="img-fluid"
                  width="50"
                  alt="image"
                />
                <h6
                  class="text-colorText font-weight-bolder text-center ml-50 mb-0"
                >
                  {{ department.name }}
                </h6>
              </div>
            </router-link>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      departments: [],
      doctors: [],
      procedures: [],
      count_hospital: 0,
      visibleDepartments: [],
      startIndex: 0,
      itemsPerPage: 8,
    };
  },

  methods: {
    ...mapActions({
      getdepartments: "appData/getDepartmentsPublicNav",
      getdoctors: "appData/getDoctorsPublicNav",
      getprocedures: "appData/getProceduresPublicNav",
      gethospitals: "appData/getHospitalsPublic",
    }),

    imageUrl(url) {
      return process.env.VUE_APP_MEDIA + url;
    },
    async fetchData() {
      const res_doctors = await this.getdoctors();
      this.doctors = res_doctors.data;

      const res_hospitals = await this.gethospitals();
      this.hospitals = res_hospitals.data;
      this.count_hospital = this.hospitals.length;

      const res_departments = await this.getdepartments();
      this.departments = res_departments.data;

      const res_procedures = await this.getprocedures();
      this.procedures = res_procedures.data;
    },
  },
  async mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/images/procedures-overlay.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.my-col {
  border: 2px solid #091e3d;
  background-color: rgb(6, 189, 70, 0.1);
  box-shadow: 2px 2px #091e3d;
  transition: 0.3s ease;
}
.my-col:hover {
  background-color: rgb(6, 189, 70, 0.2);
  transform: translateY(-5px);
}
</style>
